import { CircularProgress, TableFooter, TablePagination } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { debounce, forEach, get, set as set_, size } from 'lodash'

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { DateTime } from "luxon";
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import ImageList from "@mui/material/ImageList"
import ImageListItem from "@mui/material/ImageListItem"
import ImageListItemBar from "@mui/material/ImageListItemBar"
import InputLabel from '@mui/material/InputLabel';
import LinearProgress from '@mui/material/LinearProgress';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';

const GettyViewGrid = (props: {
  data: any,
  loading: any,
  error: any
}) => {
  let labels = get(props, "data.data.labels") || {}
  let overlookedTagsDetail = get(props, "data.data.ner.overlookedTagsDetail") || {}
  let summaryText = get(props, "data.data.summaryText") || ""
  let gettyImages = get(props, "data.data.result") || []
  let articleData = get(props, "data.data.articleData") || {}
  let prelimResult = get(props, "data.intermediateResult") || []
  let tagSlugs = get(articleData, "tagSlugs") || []
  console.log("gettyImages", gettyImages, overlookedTagsDetail)


  let snackbar = <div />
  return (
    <>
      {snackbar}
      <Grid>
        <Container maxWidth={false} sx={{ background: "#F6F6F6", marginTop: "10px", padding: "10px" }}>
          <Box sx={{ width: "100%" }}>
            <Paper>
              {get(articleData, "title") ?
                <Table>
                  <TableBody>
                    <TableContainer sx={{ background: "#D6D6D6" }}>
                      <TableRow >
                        <TableCell>Title</TableCell>
                        <TableCell>{get(articleData, "title")}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Text</TableCell>
                        <TableCell>{get(articleData, "longText")}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Excerpt</TableCell>
                        <TableCell>{get(articleData, "excerpt")}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Tags</TableCell>
                        <TableCell>
                          <Table>
                            <TableBody>
                              {
                                tagSlugs ? tagSlugs.map((slug: any) => {
                                  return (
                                    <>
                                      <TableRow>
                                        <TableCell>
                                          {slug}<br />
                                        </TableCell>
                                      </TableRow>
                                    </>
                                  )
                                }) : ""}
                            </TableBody>
                          </Table>
                        </TableCell>
                      </TableRow>
                    </TableContainer>
                    <TableContainer>
                      <TableRow>
                        <TableCell>OverlookedTags</TableCell>
                        <TableCell>
                          <Table>
                            <TableBody>
                              {
                                overlookedTagsDetail ? Object.keys(overlookedTagsDetail).map((key) => {
                                  let row = get(overlookedTagsDetail, key)
                                  return (
                                    <>
                                      <TableRow>
                                        <TableCell>
                                          Name: <b>{get(row, "word")}</b><br />
                                          Slug: {key}<br />
                                          Type: {get(row, "type")}<br />
                                          termTaxonomyId: {get(row, "termTaxonomyId")}<br />
                                          score: {get(row, "score")}<br />
                                        </TableCell>
                                      </TableRow>
                                    </>
                                  )
                                }) : ""}
                            </TableBody>
                          </Table>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Summmary</TableCell>
                        <TableCell>{summaryText}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Labels</TableCell>
                        <TableCell>{JSON.stringify(labels)}</TableCell>
                      </TableRow>
                    </TableContainer>
                  </TableBody>
                </Table> : ""
              }
              <ImageList sx={{ width: "100%" }} cols={4}>
                {gettyImages.map((row: any) => (
                  <ImageListItem key={get(row, "id")}>
                    <img
                      src={`${get(row, "display_sizes.0.uri")}`}
                      alt={`${get(row, "caption")}`}
                      loading="lazy"
                    />
                    <ImageListItemBar
                      title={get(row, "title")}
                      subtitle={get(row, "caption")}
                      position="bottom"

                    />
                  </ImageListItem>

                ))}
              </ImageList>
            </Paper>
          </Box>
        </Container>

      </Grid >
    </>
  )
}

export default GettyViewGrid;
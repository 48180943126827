import {
  Link,
  NavLink,
  NavLinkProps,
  Outlet,
  Route,
  RouteProps,
  Routes,
} from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import OpenaiTitleView from "app/openai/openaiTitleView"
import Paper from "@mui/material/Paper";
import React from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { get } from "lodash";
import { useAsync } from 'react-use';

const GettyContainer = () => {
  const [contentUrl, setContentUrl] = React.useState<string>("");
  const [prompt, setPrompt] = React.useState<string>("give 3 variations of seo titles for this article");
  const [temperature, setTemperature] = React.useState<number>(0.8);
  const [maxTokens, setMaxTokens] = React.useState<number>(100);
  const [isSearching, setIsSearching] = React.useState<boolean>(false);
  const [isSearchingCtrl, setIsSearchingCtrl] = React.useState<boolean>(false);
  const handleUrlChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    setContentUrl(event.target.value)
  }
  const handlePromptChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    setPrompt(event.target.value)
  }
  const handleTemperatureChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    setTemperature(parseFloat(event.target.value))
  }
  const handleMaxTokensChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    setMaxTokens(parseInt(event.target.value))
  }
  const handleFetchImage = async () => {
    if (contentUrl && isSearching) {
      let imageUrl = `//${window.location.hostname}/copilot/genesys/openai/prompt/withurl`;
      if (window.location.port) {
        imageUrl = `//${window.location.hostname}:${window.location.port}/copilot/genesys/openai/prompt/withurl`;
      }
      console.log("contentUrl", contentUrl);
      let result = await axios.post(imageUrl, {
        url: contentUrl, "prompt": prompt,
        "temperature": temperature,
        "max_tokens": maxTokens
      });
      console.log("contentUrl result", get(result, "data"));
      setIsSearching(false)
      return result;
    }
    setIsSearching(false)
  }
  const handleSearch = () => {
    if (!isSearching) {
      setIsSearching(true)
      setIsSearchingCtrl(!isSearchingCtrl)
    }
  }
  const { loading, value, error } = useAsync(handleFetchImage, [isSearchingCtrl]);
  console.log("result async", loading, value, error)

  return (
    <Box component="main" sx={{ display: "flex" }}>
      <Paper sx={{ "justify-content": "center", "margin-top": "25px" }}>
        <Grid container sx={{ marginTop: "10px", padding: "10px" }} spacing={2}>
          <Grid item xs={12} sx={{ "justify-content": "center" }}>
            {loading ? <LinearProgress /> : <div />}
            <Typography variant="h4" component="h4" >
              OpenAI AI Assisted Titles
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField onChange={handleUrlChange} fullWidth label="Url" />
          </Grid>
          <Grid item xs={12}>
            <TextField onChange={handlePromptChange} fullWidth label="Prompt" multiline
              rows={4} value={prompt} />
          </Grid>
          <Grid item xs={4}>
            <TextField onChange={handleTemperatureChange} fullWidth label="temperature (0.0 to 1.0)" value={temperature} />
          </Grid>
          <Grid item xs={4}>
            <TextField onChange={handleMaxTokensChange} fullWidth label="max tokens (500)" value={maxTokens} />
          </Grid>
          <Grid item xs={4}>
            <Button variant="contained" onClick={handleSearch} disabled={isSearching}>Search</Button>
          </Grid>
          <OpenaiTitleView data={value} loading={loading} error={error} />
        </Grid >
      </Paper>
    </Box >
  );
}

export default GettyContainer;
import {
  Link,
  NavLink,
  NavLinkProps,
  Outlet,
  Route,
  RouteProps,
  Routes,
} from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import GettyContainer from "app/getty/gettyContainer"
import Grid from '@mui/material/Grid';
import OpenaiPromptContainer from "app/openai/openaiPromptContainer"
import OpenaiTitleContainer from "app/openai/openaiTitleContainer"
import Paper from "@mui/material/Paper";
import React from "react";
import TextField from "@mui/material/TextField";
import TitleContainer from "app/title/titleContainer"
import axios from "axios";
import { get } from "lodash";

const AppCcrm = () => {
  return (
    <Container maxWidth={false} disableGutters={false} >
      <Outlet></Outlet>
    </Container>
  );
}



const routes = () => {
  return (

    <Routes>
      <Route element={<AppCcrm />}>
        <Route path="/singularity/view"  >
          <Route path="getty" element={<GettyContainer />} />
          <Route path="title" element={<TitleContainer />} />
          <Route path="openai" >
            <Route path="title" element={<OpenaiTitleContainer />} />
            <Route path="prompt" element={<OpenaiPromptContainer />} />
          </Route>

        </Route>
      </Route >
    </Routes >

  )
}


export default routes;

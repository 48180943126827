import {
  Link,
  NavLink,
  NavLinkProps,
  Outlet,
  Route,
  RouteProps,
  Routes,
} from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from "@mui/material/Paper";
import React from "react";
import TextField from "@mui/material/TextField";
import TitleView from "app/title/titleView"
import Typography from "@mui/material/Typography";
import axios from "axios";
import { get } from "lodash";
import { useAsync } from 'react-use';

const GettyContainer = () => {
  const [contentUrl, setContentUrl] = React.useState<string>("");
  const [isSearching, setIsSearching] = React.useState<boolean>(false);
  const [isSearchingCtrl, setIsSearchingCtrl] = React.useState<boolean>(false);
  const handleUrlChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    setContentUrl(event.target.value)
  }
  const handleFetchImage = async () => {
    if (contentUrl && isSearching) {
      let imageUrl = `//${window.location.hostname}/copilot/genesys/t5large/xxlmag/withurl`;
      if (window.location.port) {
        imageUrl = `//${window.location.hostname}:${window.location.port}/copilot/genesys/t5large/xxlmag/withurl`;
      }
      console.log("contentUrl", contentUrl);
      let result = await axios.post(imageUrl, { url: contentUrl });
      console.log("contentUrl result", get(result, "data"));
      setIsSearching(false)
      return result;
    }
    setIsSearching(false)
  }
  const handleSearch = () => {
    if (!isSearching) {
      setIsSearching(true)
      setIsSearchingCtrl(!isSearchingCtrl)
    }
  }
  const { loading, value, error } = useAsync(handleFetchImage, [isSearchingCtrl]);
  console.log("result async", loading, value, error)

  return (
    <Box component="main" sx={{ display: "flex" }}>
      <Paper sx={{ "justify-content": "center", "margin-top": "25px" }}>
        <Grid container sx={{ marginTop: "10px", padding: "10px" }}>
          <Grid item xs={12} sx={{ "justify-content": "center" }}>
            {loading ? <LinearProgress /> : <div />}
            <Typography variant="h4" component="h4" >
              TSQ AI Assisted Titles
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField onChange={handleUrlChange} fullWidth />
          </Grid>
          <Grid item xs={4}>
            <Button variant="contained" onClick={handleSearch} disabled={isSearching}>Search</Button>
          </Grid>
          <TitleView data={value} loading={loading} error={error} />
        </Grid >
      </Paper>
    </Box >
  );
}

export default GettyContainer;
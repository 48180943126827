import { darkTheme, lightTheme } from "theme/theme";

import AppCopilot from "app/appCopilot";
import { BrowserRouter } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { useState } from "react";

// const client = new ApolloClient({
//   uri: "http://localhost:5001/ccrm/services/graphql",
//   cache: new InMemoryCache(),
// });

function App() {
  const [mode, setMode] = useState("dark");
  // const selectedTheme = mode === "dark" ? darkTheme : lightTheme;
  const selectedTheme = darkTheme;
  console.log("mode", mode, selectedTheme);
  return (
    <BrowserRouter>
      <ThemeProvider theme={selectedTheme}>
        <CssBaseline />
        <AppCopilot />
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
import { CircularProgress, TableFooter, TablePagination } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { debounce, forEach, get, set as set_, size } from 'lodash'

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { DateTime } from "luxon";
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import ImageList from "@mui/material/ImageList"
import ImageListItem from "@mui/material/ImageListItem"
import ImageListItemBar from "@mui/material/ImageListItemBar"
import InputLabel from '@mui/material/InputLabel';
import LinearProgress from '@mui/material/LinearProgress';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';

const GettyViewGrid = (props: {
  data: any,
  loading: any,
  error: any
}) => {
  let predictedTitles = get(props, "data.data.result.chatgpt.choices.0.text") || ""
  let summaryText = get(props, "data.data.summaryText") || ""
  let articleData = get(props, "data.data.articleData") || {}
  let tagSlugs = get(articleData, "tagSlugs") || []
  let predictedTitlesArray = predictedTitles.split("\n")

  let snackbar = <div />
  return (
    <>
      {snackbar}
      {props.loading ? <LinearProgress /> : <div />}
      <Grid>
        <Container maxWidth={false} sx={{ background: "#F6F6F6", marginTop: "10px", padding: "10px" }}>
          <Box sx={{ width: "100%" }}>
            <Paper>
              {predictedTitles ?
                <Table>
                  <TableBody>
                    <TableContainer>
                      <TableRow>
                        <TableCell>Answer</TableCell>
                        <TableCell>
                          {
                            predictedTitlesArray ? predictedTitlesArray.map((title: any) => {
                              return (
                                <>
                                  {title}<br />
                                </>
                              )
                            }) : ""}
                        </TableCell>
                      </TableRow>
                    </TableContainer>
                  </TableBody>
                </Table> : ""
              }
            </Paper>
          </Box>
        </Container>

      </Grid >
    </>
  )
}

export default GettyViewGrid;